<template>
  <div :class="$style.settings">
    <div :class="$style.wrapper">
      <el-button size="small" type="primary" @click="submitForm()"
        >Сохранить</el-button
      >
    </div>
    <el-form :model="form" ref="form" label-position="left" label-width="25rem">
      <h2 :class="$style.name">Настройки рассылки уведомлений</h2>
      <div :class="$style.ratios">
        <el-form-item
          label="Кол-во дней до дня рождения пользователя"
          prop="bonusAccrualRatio"
        >
          <el-input-number
            v-model.number="form.daysBeforeBirthday"
            controls-position="right"
            :step="1"
            :min="1"
          />
        </el-form-item>

        <el-form-item prop="bonusCount">
          <div slot="label" :class="$style.label">
            <span>Кол-во начисляемых бонусов в честь дня рождения</span>
            <span :class="$style.warning">
              В сообщении можно указать количество бонус и дату сгорания,
              используя {bonusCount} и {bonusExpiration} соответственно
            </span>
          </div>

          <el-input-number
            v-model.number="form.bonusCount"
            controls-position="right"
            :step="1"
            :min="1"
          />
        </el-form-item>

        <el-form-item
          label="Кол-во дней до сгорания бонусов"
          prop="bonusExpiration"
        >
          <el-input-number
            v-model.number="form.bonusExpiration"
            controls-position="right"
            :step="1"
            :min="1"
          />
        </el-form-item>
        <el-form-item label="Текст поздравления" prop="content">
          <el-input
            v-model="form.message"
            type="textarea"
            resize="none"
            :rows="4"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'

export default {
  mixins: [notifications],
  async created() {
    await this.getPaymentSettings()
  },
  data() {
    return {
      form: {
        daysBeforeBirthday: 0,
        bonusCount: 0,
        bonusExpiration: 0,
        message: '',
      },
    }
  },
  methods: {
    async getPaymentSettings() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AccountsCore.NotificationsSettingsActions.get()
      loading.close()

      if (error) return

      this.form = value
    },
    async submitForm() {
      const loading = this.$loading({
        lock: true,
      })

      const result =
        await delivery.AccountsCore.NotificationsSettingsActions.save(this.form)
      loading.close()

      if (result.error)
        return this.showNotification(
          `Ошибка редактирования настроек: ${result.error}`,
          'error',
        )

      this.showNotification('Настройки успешно изменены', 'success')

      this.getPaymentSettings()
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.settings {
  padding: 0 1rem;
  .wrapper {
    @include stickyWrapper;
  }
  .ratios {
    display: flex;
    flex-direction: column;

    .label {
      line-height: 0.6rem;
      .warning {
        display: block;
        margin-top: 1rem;
        @include text-button;
        color: $warning;
        font-size: 0.7rem;
      }
    }
  }
  .name {
    margin-bottom: 1rem;
    margin-left: 1.25rem;
  }
}
</style>
